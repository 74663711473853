import React, { useEffect, useState } from 'react';
import "../styles/Concierge.css"
import { Navigate } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
const Concierge: React.FC = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const isAdminStored = sessionStorage.getItem("isAdmin");
        if (isAdminStored === null || isAdminStored === undefined) {
            setShowPopup(true);
        }
    }, []);

    return !showPopup ? (
        <div className='concierge'>
            <div className='content-container'>
                <h1>Concierge details below. Our concierge team will reach out to you directly as well</h1>
                <p className='subtext'>
                    {"For questions, travel support, and further information, please reach out to our concierge using the\nWhatsApp link below or via email at sabiandani25@gmail.com."}
                </p>
                {/* Icons moved below the text */}
                <div className='contact-icons'>
                    <a
                        href="https://wa.me/916363194930"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaWhatsapp size={50} color="#25D366" className='icon' />
                    </a>
                    <a href="mailto:sabiandani25@gmail.com">
                        <MdEmail size={50} color="#b85089" className='icon' />
                    </a>
                </div>
            </div>
        </div >
    ) : (
        <Navigate to="/" />
    );
};

export default Concierge;